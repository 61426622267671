import React, { useState } from "react";
import { Box, IconButton, Typography, Button } from "@mui/material";
import { presale, roadmap, logo } from "../Images";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import RepeatIcon from "@mui/icons-material/Repeat";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import IosShareIcon from "@mui/icons-material/IosShare";
import CircleIcon from "@mui/icons-material/Circle";
import Icons from "../SmallComponent/Icons";

const iconArray = [
  {
    icon: (
      <ChatBubbleOutlineOutlinedIcon
        sx={{ color: "#6B6F74", fontSize: { xs: "11px", sm: "17px" } }}
      />
    ),
    text: "17k",
  },
  {
    icon: (
      <RepeatIcon
        sx={{ color: "#6B6F74", fontSize: { xs: "11px", sm: "17px" } }}
      />
    ),
    text: "190k",
  },
  {
    icon: (
      <FavoriteBorderIcon
        sx={{ color: "#6B6F74", fontSize: { xs: "11px", sm: "17px" } }}
      />
    ),
    text: "13M",
  },
  {
    icon: (
      <BookmarkBorderIcon
        sx={{ color: "#6B6F74", fontSize: { xs: "11px", sm: "17px" } }}
      />
    ),
    text: "107k",
  },
  {
    icon: (
      <IosShareIcon
        sx={{ color: "#6B6F74", fontSize: { xs: "11px", sm: "17px" } }}
      />
    ),
    text: "202k",
  },
];

const roadmapArray = [
  {
    heading:
      "Phase 1: Project Preparation and Market Entry Strategy (Completed)",
    points: [
      { bullet: "Initial planning and whitepaper creation." },
      { bullet: "Development of marketing strategy and community building." },
      {
        bullet:
          "Establishment of pricing model based on Trump’s Twitter follower count.",
      },
    ],
  },
  {
    heading: "Phase 2: Presale Launch (Ongoing)",
    pr: 2,
    points: [
      {
        bullet:
          "Presale Launch and Marketing Boost: Conducting a 60-day presale campaign and collaborating with major cryptocurrency news platforms to promote the project.",
      },
    ],
  },
  {
    heading: "Phase 3: Listing and Airdrop Distribution",
    points: [
      {
        bullet:
          "Announcement of Final Listing Price: Final listing price to be announced after the presale ends.",
      },
      {
        bullet:
          "Airdrop Distribution and Claim Process: Airdrop distribution to round-based buyers will begin.  ",
      },
      {
        bullet:
          "Listing on Major Exchanges: YourTrump tokens will be listed on major cryptocurrency exchanges.",
      },
      {
        bullet:
          "Further Listing Discussions: Discussions to expand listings on additional exchanges and form new partnerships will commence.",
      },
    ],
  },
  {
    heading: "Phase 4: Community Engagement and Project Expansion",
    points: [
      {
        bullet:
          "Strengthening Community Management and Ongoing Communication with Investors.",
      },
      {
        bullet: "Announcement of Project Updates and Roadmap Expansion Plans. ",
      },
      {
        bullet:
          "Expanding the Ecosystem through Additional Features and Partnerships.",
      },
    ],
  },
  {
    heading: "Phase 5: Long-Term Growth and Project Expansion",
    points: [
      {
        bullet: "Introduction of New Features and Services.",
      },
      {
        bullet: "Project Improvement Based on Investor Feedback.  ",
      },
      {
        bullet:
          "Ongoing Updates for Ecosystem Expansion and Sustainable Project Growth.  ",
      },
    ],
  },
];

const Roadmap = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Box sx={{ mt: -2, borderBottom: "1px solid #2F3336" }} id="Roademap">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: { xs: 1, lg: 2 },
        }}
      >
        <Box sx={{ display: "flex", gap: "0 10px" }}>
          <Box sx={{ width: { xs: "25px", md: "40px" } }}>
            <img
              src={logo}
              alt=""
              style={{
                width: "100%",
                objectFit: "contain",
                borderRadius: "49%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "calc(100% - 35px)" },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: "0px 10px" }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "18px",
                    fontFamily: "HelveticaNeueBlackBold",
                  }}
                >
                  YourTrump
                </Typography>
                <Typography sx={{ color: "#4B4D51" }}>$YTP</Typography>
              </Box>
              <Box>
                <MoreHorizSharpIcon
                  sx={{
                    fontSize: "24px",
                    color: "#757575",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "20px 0px" }}
            >
              {roadmapArray
                .slice(0, showMore ? roadmapArray.length : 1)
                .map((item, index) => {
                  return (
                    <Box key={index}>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: { xs: "16px", md: "20px" },
                          fontFamily: "HelveticaNeueBlack",
                        }}
                      >
                        {item.heading}
                      </Typography>
                      {item.points.map((item2, index2) => (
                        <Box
                          key={index2}
                          sx={{
                            display: "flex",
                            gap: "0px 10px",
                            pl: { xs: 1, md: 3 },
                          }}
                        >
                          <CircleIcon
                            sx={{
                              color: "#fff",
                              fontSize: "10px",
                              mt: "8px",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: { xs: "14px", md: "16px" },
                              fontFamily: "HelveticaNeueBlack",
                              color: "#fff",
                            }}
                          >
                            {item2.bullet}
                          </Typography>
                        </Box>
                      ))}
                      {!showMore && (
                        <Button
                          onClick={() => setShowMore(true)}
                          sx={{
                            color: "#1D9AEF",
                            ml: 4,
                            fontFamily: "HelveticaNeueBlack",
                          }}
                        >
                          Show More
                        </Button>
                      )}
                    </Box>
                  );
                })}

              <Box sx={{ py: 3 }}>
                <Box sx={{ width: "100%", height: "100%", mx: "auto" }}>
                  <img
                    src={roadmap}
                    alt=""
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      height: "100%",
                      borderRadius: "15px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ pb: 2 }}>
        <Box
          sx={{
            px: 2,
            pl: { xs: 4, sm: 4, md: 8 },
            mt: -2,
          }}
        >
          <Icons reply="48K" reTweet="109K" like="857K" view="86M" />
        </Box>
      </Box>
    </Box>
  );
};

export default Roadmap;
