import { Box } from "@mui/material";
import React from "react";

export const StyledBox = (props) => {
  return (
    <Box
      {...props}
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: { xs: "column" },
        alignItems: "center",
        gap: "8px 5px",
        background: "",
        width: { xs: "60px", sm: "69px" },
        height: { xs: "61px", sm: "70px" },
        // borderRadius: "15px",
        boxSizing: "border-box",
      }}
    >
      {props.children}{" "}
    </Box>
  );
};
