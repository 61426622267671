/* eslint-disable import/order */
import { Box,  } from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";

import "./Swiper.css";

// import required modules
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  t1,
  t2,
  t3,
  t4,
  t5,
  t6,
  t7,
  t8,
  t9,
  t10,
  t11,
  t12,
  t13,
} from "../Images";
// import React, { useState } from "react";

const whyArray = [
  {
    img: t1,
  },
  {
    img: t2,
  },

  {
    img: t3,
  },
  {
    img: t4,
  },
  {
    img: t5,
  },
  {
    img: t6,
  },
  {
    img: t7,
  },
  {
    img: t8,
  },
  {
    img: t9,
  },
  {
    img: t10,
  },
  {
    img: t11,
  },
  {
    img: t12,
  },
  {
    img: t13,
  },
];

const WhySlider = () => {

  return (
    <Box
      maxWidth="500px"
      sx={{
        mx: "auto",
        width: { xs: "240px", sm: "400px", md: "330px", lg: "270px" },
      }}
    >
      <Box sx={{ color: "#fff", mx: "auto" }}>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
          speed={0}
          loop={true}
        >
          {whyArray.map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                style={{
                  background: "none",
                  display: "flex",
                  justifyContent: "flex-start",
                  height: "100%",
                  // paddingBottom: "40px",
                }}
              >
                <Box sx={{ width: "80%", mx: "auto" }}>
                  <img src={item.img} alt="" style={{ width: "100%" }} />
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Box>
  );
};

export default WhySlider;
