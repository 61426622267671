import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";

import EmailIcon from "@mui/icons-material/Email";
import { Link as ScrollLink } from "react-scroll";
import {
  audit,
  logo,
  navAbout,
  navContactUs,
  navFaqs,
  navHowToBuy,
  navRoadmap,
  navTokenomics,
  telegram,
  WP,
} from "../Images";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor) => () => {
    setState((prevState) => ({ ...prevState, [anchor]: !prevState[anchor] }));
  };

  const downloadPDF = () => {
    const pdfUrl = "/whitepaper.pdf"; // Adjust the path to match the exact file name
    window.open(pdfUrl, "_blank");
  };
  const downloadAudit = () => {
    const pdfUrl = "/Audit.pdf"; // Adjust the path to match the exact file name
    window.open(pdfUrl, "_blank");
  };

  const array = [
    {
      name: "",
      link: "https://tr.ee/YTP_x",
      icons: <XIcon sx={{ color: "#FEFEFF", fontSize: "34px" }} />,
    },
    {
      name: "Telegram",
      link: "https://tr.ee/YTP_telegram",
      img: telegram,
    },
  ];

  const array2 = [
    {
      name: "About YourTrump",
      link1: "presale",
      offset: -130,
      img: navAbout,
    },
    {
      name: "Tokenomics",
      link1: "Tokenomics",
      offset: -137,
      img: navTokenomics,
    },
    {
      name: "Roadmap",
      link1: "Roademap",
      offset: -130,
      img: navRoadmap,
    },
    {
      name: "How To Buy",
      link1: "howtobuy",
      offset: -130,
      img: navHowToBuy,
    },
    {
      name: "Faqs",
      link1: "faqs",
      offset: -144,
      img: navFaqs,
    },
    {
      name: "Audit",
      img: audit,
      click: downloadAudit,
    },
    {
      name: "White Paper",
      img: WP,
      click: downloadPDF,
    },
  ];

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 230,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100%",
        pt: 8,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor)}
      onKeyDown={toggleDrawer(anchor)}
    >
      {/* Navigation Links */}
      <Box sx={{}}>
        <List>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px 0" }}>
            {array.map((item, index) => (
              <ListItem key={item.name} disablePadding>
                <ListItemButton
                  component="a"
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.img ? (
                    <>
                      <Box sx={{ width: "27px" }}>
                        <img
                          src={item.img}
                          alt=""
                          srcSet=""
                          style={{ width: "100%", objectFit: "contain" }}
                        />
                      </Box>
                    </>
                  ) : (
                    <ListItemIcon sx={{}}>{item.icons} </ListItemIcon>
                  )}
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{
                      sx: {
                        cursor: "pointer",
                        textDecoration: "none",
                        fontSize: "14px",
                        textTransform: "uppercase",
                        color: "#fff",
                        fontFamily: "HelveticaNeueBlack",
                        pl: 1,
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
          {array2.map((item, index) => (
            <ScrollLink
              onClick={item.click ? item.click : ""}
              key={index}
              to={item.link1}
              spy={true}
              smooth={true}
              offset={item.offset}
              duration={1000}
              style={{
                textDecoration: "none",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              <ListItem
                disablePadding
                onClick={toggleDrawer(anchor)}
                onKeyDown={toggleDrawer(anchor)}
              >
                <ListItemButton>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Box
                      sx={{
                        width: "27px",
                        background: "#000",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={item.img}
                        alt={item.name}
                        style={{ width: "100%", objectFit: "contain" }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        textTransform: "uppercase",
                        fontFamily: "HelveticaNeueBlack",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </ListItemButton>
              </ListItem>
            </ScrollLink>
          ))}
        </List>
      </Box>
      {/* Footer */}

      <Box
        sx={{
          px: 2,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          pb: 3,
        }}
      >
        <Button
          LinkComponent={Link}
          target="_blank"
          to="https://linktr.ee/realYourTrump"
          sx={{
            background: "#619AEB",
            color: "#fff",
            borderRadius: "25px",
            py: 1.3,
            px: 3,
          }}
        >
          Contact Us
        </Button>
        <Box
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            mt: 2,
          }}
        >
          <Box
            sx={{
              px: 2,
              width: "100%",
              display: "flex",
              gap: "0 10px",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                width: { xs: "25px", md: "49px" },
              }}
            >
              <img
                src={logo}
                alt=""
                srcSet=""
                style={{
                  width: "100%",
                  objectFit: "contain",
                  borderRadius: "49%",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "0px 0px",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "18px",
                  fontFamily: "HelveticaNeueBlackBold",
                }}
              >
                YourTrump
              </Typography>
              <Typography
                sx={{
                  color: "#4B4D51",
                  fontFamily: "HelveticaNeueBlack",
                }}
              >
                @YTP
              </Typography>
            </Box>
          </Box>
          <Box>
            <IconButton sx={{}}>
              <MoreHorizSharpIcon
                sx={{
                  fontSize: "24px",
                  color: "#757575",
                  fontFamily: "HelveticaNeueBlack",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor} style={{ background: "#000" }}>
          <IconButton onClick={toggleDrawer(anchor)} sx={{ pl: 0 }}>
            <MenuIcon sx={{ color: "#fff" }} />
          </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor)}
            onOpen={toggleDrawer(anchor)}
            PaperProps={{
              sx: {
                backgroundColor: "#000",
                color: "#fff",
              },
            }}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
