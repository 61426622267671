import React from "react";
import { FaRegComment } from "react-icons/fa";
import { BiRepost } from "react-icons/bi";
import { FaRegHeart } from "react-icons/fa";
import BarChartIcon from "@mui/icons-material/BarChart";
import { CiBookmark } from "react-icons/ci";
import { FiShare } from "react-icons/fi";
import { Box, IconButton, Typography, useMediaQuery } from "@mui/material";

const Icons = ({ reply, reTweet, like, view }) => {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "0 5px",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      {/* Comment Icon */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton sx={{ padding: { xs: "6px", sm: "8px" } }}>
          <FaRegComment
            style={{
              color: "#6B6F74",
              fontSize: matches ? "24px" : "14px",
            }}
          />
        </IconButton>
        <Typography
          sx={{
            color: "#6B6F74",
            fontSize: { xs: "10px", sm: "14px", md: "17px" },
            fontFamily: "HelveticaNeueBlack",
          }}
        >
          {reply}
        </Typography>
      </Box>

      {/* Repost Icon */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton sx={{ padding: { xs: "6px", sm: "8px" } }}>
          <BiRepost
            style={{
              color: "#6B6F74",
              fontSize: matches ? "30px" : "17px",
            }}
          />
        </IconButton>
        <Typography
          sx={{
            color: "#6B6F74",
            fontSize: { xs: "10px", sm: "14px", md: "17px" },
            fontFamily: "HelveticaNeueBlack",
          }}
        >
          {reTweet}
        </Typography>
      </Box>

      {/* Like Icon */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton sx={{ padding: { xs: "6px", sm: "8px" } }}>
          <FaRegHeart
            style={{
              color: "#6B6F74",
              fontSize: matches ? "22px" : "12px",
            }}
          />
        </IconButton>
        <Typography
          sx={{
            color: "#6B6F74",
            fontSize: { xs: "10px", sm: "14px", md: "17px" },
            fontFamily: "HelveticaNeueBlack",
          }}
        >
          {like}
        </Typography>
      </Box>

      {/* Graph Icon */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton sx={{ padding: { xs: "6px", sm: "8px" } }}>
          <BarChartIcon
            sx={{
              color: "#6B6F74",
              fontSize: { xs: "14px", sm: "24px" },
            }}
          />
        </IconButton>
        <Typography
          sx={{
            color: "#6B6F74",
            fontSize: { xs: "10px", sm: "14px", md: "17px" },
            fontFamily: "HelveticaNeueBlack",
          }}
        >
          {view}
        </Typography>
      </Box>

      {/* Save and Share Icons */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <IconButton sx={{ padding: { xs: "6px", sm: "8px" } }}>
          <CiBookmark
            style={{
              color: "#6B6F74",
              fontSize: matches ? "24px" : "14px",
            }}
          />
        </IconButton>
        <IconButton sx={{ padding: { xs: "6px", sm: "8px" } }}>
          <FiShare
            style={{
              color: "#6B6F74",
              fontSize: matches ? "20px" : "14px",
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Icons;
