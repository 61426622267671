import { Box, Container, IconButton, Typography } from "@mui/material";
import React from "react";
import { howtobuy, logo } from "../Images";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import Icons from "../SmallComponent/Icons";

const howToBuy = [
  {
    heading: "Connect Your Wallet",
    text: "If you already have a compatible wallet, proceed to step 2. If not, we recommend using Metamask for desktop. For mobile, we suggest using Best Wallet. Download the app and get started!",
    box: "as",
    align: "as",
    reply: "20K",
    reTweet: "58K",
    like: "452K",
    view: "44M",
  },
  {
    heading: "Fund Your Wallet with Cryptocurrency",
    text: "Load your preferred cryptocurrency into the wallet. ETH, USDT, and BNB are accepted.",
    box: "as",
    align: "as",
    reply: "19K",
    reTweet: "62K",
    like: "316K",
    view: "66M",
  },
  {
    heading: (
      <>
        Purchase <span style={{ color: "#1D9BF0" }}>$YTP</span>
      </>
    ),
    text: (
      <>
        Want more profit? Connect your wallet to the website. Choose your
        payment method, select the amount of{" "}
        <span style={{ color: "#1D9BF0" }}>$YTP</span> you'd like to purchase,
        and click 'Buy' to maximize your earnings.
      </>
    ),
    reply: "8.7K ",
    reTweet: "31K",
    like: "191K",
    view: "16M",
  },
];

const HowToBuy = () => {
  return (
    <Box
      id="howtobuy"
      sx={{
        boxSizing: "border-box",
        borderBottom: "1px solid #2F3336",
        mt: -3,
      }}
    >
      <Box sx={{ display: "flex", gap: "0 10px", mx: { xs: 1, md: 2 } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: { xs: "25px", md: "40px" } }}>
            <img
              src={logo}
              alt=""
              srcSet=""
              style={{
                width: "100%",
                objectFit: "contain",
                borderRadius: "49%",
              }}
            />
          </Box>
          <Box sx={{ width: "2px", height: "100%", background: "#393D40" }} />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: "0 10px" }}>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0 10px" }}
            >
              <Box>
                <Box sx={{}}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0 10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "17px",
                          display: "flex",
                          alignItems: "center",
                          gap: "0 7px",
                          fontFamily: "HelveticaNeueBlackBold",
                        }}
                      >
                        YourTrump{" "}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#4B4D51",
                          fontFamily: "HelveticaNeueBlack",
                        }}
                      >
                        $YTP
                      </Typography>
                    </Box>
                    <Box>
                      <MoreHorizSharpIcon
                        sx={{ fontSize: "24px", color: "#757575" }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{}}>
                    <Typography
                      sx={{ color: "#fff", fontFamily: "HelveticaNeueBlack" }}
                    >
                      How To Buy
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      pt: 3,
                      width: {
                        xs: "calc(100% - 35px)",
                        md: "calc(100% - 35px)",
                      },
                      mx: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        mx: "auto",
                        pl: { xs: "0px", md: "0px" },
                      }}
                    >
                      <img
                        src={howtobuy}
                        alt=""
                        srcSet=""
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          height: "100%",
                          borderRadius: "15px",
                        }}
                      />
                    </Box>
                    <Box sx={{ mt: -1 }}>
                      <Icons reply="30K" reTweet="69K" like="408K" view="63M" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          py: 2,
          display: "flex",
          flexDirection: "column",
          gap: "10px 0",
          px: { xs: 1, md: 2 },
        }}
      >
        {howToBuy.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: { xs: "0 10px", sm: "0 20px" },
                mt: item.marginTop ? item.marginTop : 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: item.align ? "center" : "",
                  justifyContent: item.align ? "center" : "",
                }}
              >
                <Box sx={{ width: { xs: "25px", md: "40px" } }}>
                  <img
                    src={logo}
                    alt=""
                    srcSet=""
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      borderRadius: "49%",
                    }}
                  />
                </Box>
                {item.box ? (
                  <>
                    <Box
                      sx={{
                        width: "2px",
                        background: "#393D40",
                        color: "#393D40",
                        height: "100%",
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "14px 0",
                  width: "100%",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "0 14px" }}>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "17px",
                          display: "flex",
                          alignItems: "center",
                          gap: "0 7px",
                          fontFamily: "HelveticaNeueBlackBold",
                        }}
                      >
                        YourTrump{" "}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#4B4D51",
                          fontFamily: "HelveticaNeueBlack",
                        }}
                      >
                        $YTP
                      </Typography>
                    </Box>
                    <Box>
                      <MoreHorizSharpIcon
                        sx={{ fontSize: "24px", color: "#757575" }}
                      />
                    </Box>
                  </Box>

                  <Box>
                    <Typography
                      sx={{ color: "#fff", fontFamily: "HelveticaNeueBlack" }}
                    >
                      {item.heading}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderRadius: "10px",
                    border: "1px solid grey",
                    p: 2,
                    width: "100%",
                    // mx: 2,
                  }}
                >
                  <Box sx={{ display: "flex", gap: "0 17px" }}>
                    <Box sx={{ width: { xs: "20px", md: "33px" } }}>
                      <img
                        src={logo}
                        alt=""
                        srcSet=""
                        style={{
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: "49%",
                        }}
                      />
                    </Box>
                    <Box>
                      <Box sx={{ display: "flex", gap: "0 14px" }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "17px",
                            display: "flex",
                            alignItems: "center",
                            gap: "0 7px",
                            fontFamily: "HelveticaNeueBlack",
                          }}
                        >
                          YourTrump{" "}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#4B4D51",
                            fontFamily: "HelveticaNeueBlack",
                          }}
                        >
                          $YTP
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Typography
                    sx={{ color: "#fff", fontFamily: "HelveticaNeueBlack" }}
                  >
                    {item.text}
                  </Typography>
                </Box>
                <Box sx={{ mt: -2 }}>
                  <Icons
                    reply={item.reply}
                    reTweet={item.reTweet}
                    like={item.like}
                    view={item.view}
                  />
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default HowToBuy;
