import { Alert, Snackbar } from "@mui/material";

const Toastify = ({ alertState, setAlertState }) => {
  return (
    <Snackbar
      sx={{ zIndex: 10, maxWidth: "600px" }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={5000}
      key={"bottom" + "center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
};

export default Toastify;
