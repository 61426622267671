import React, { useEffect } from "react";
import Parent from "./Components/Parent";
import { Box } from "@mui/material";
import Home from "./Components/Home/Home";
import { Route, Routes } from "react-router-dom";
import { mainnet } from "wagmi";

const App = () => {
  useEffect(() => {
    const switchToMainnet = async () => {
      try {
        if (window.ethereum) {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x1" }], // Mainnet chainId in hex
          });
        }
      } catch (error) {
        console.error("Error switching to mainnet:", error);
      }
    };

    switchToMainnet();
  }, []);

  return (
    <Box sx={{ background: "#000", boxSizing: "border-box" }}>
      <Routes>
        <Route path="/" element={<Parent />}>
          <Route index element={<Home />} />
        </Route>
      </Routes>
    </Box>
  );
};

export default App;
