import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { faq, logo } from "../Images";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import Icons from "../SmallComponent/Icons";

const faqArray = [
  {
    heading: "What is YourTrump?",
    text: "YourTrump is an innovative ICO project that sets the token price based on Donald Trump's Twitter follower count.",
    borderB: "sd",
    reply: "13K",
    reTweet: "58K",
    like: "281K",
    view: "21M",
  },
  {
    heading: "How is the token price of YourTrump determined?",
    text: "The token price of YourTrump is determined based on the last four digits of Donald Trump's Twitter follower count. This price remains fixed during the presale period, offering investors a transparent and stable pricing policy.",
    borderB: "sd",
    reply: "19K",
    reTweet: "69K",
    like: "417K",
    view: "77M",
  },
  {
    heading: "How is the listing price determined?",
    text: "The listing price of YourTrump is set based on the last five digits of Donald Trump's Twitter follower count at the end of the presale. For example, if the last five digits at the end of the presale are 56789, the listing price will be $5.6789. This mechanism provides investors with a clear benchmark to predict the post-listing price.",
    borderB: "sd",
    reply: "9.1K ",
    reTweet: "32K",
    like: "278K",
    view: "71M",
  },
  {
    heading: "How are bonuses provided during the presale period?",
    text: "The YourTrump presale will run for 60 days and is divided into 4 rounds. Each round offers different bonus incentives. In the first round, a 10% bonus is given on the purchased token amount, with the bonus percentage gradually decreasing in subsequent rounds. In the final round, the bonus rate will adjust in real-time based on the remaining token supply, and as the sale progresses, the bonus will approach.",
    borderB: "sd",
    reply: "12K",
    reTweet: "28K",
    like: "193K",
    view: "23M",
  },
  {
    heading: "What are the main benefits of the YourTrump project?",
    text: "YourTrump offers investors a fixed token price during the presale period, allowing them to invest safely without worrying about price volatility. Additionally, the price setting based on Trump’s Twitter follower count provides investors with a unique opportunity to leverage Trump’s influence. Early investors can enjoy additional benefits through bonus tokens, and the bonus structure for each round helps guide strategic investment decisions.",
    borderB: "sd",
    reply: "4.9K",
    reTweet: "25K",
    like: "187K",
    view: "20M",
  },
  {
    heading: "When will the claim and bonus airdrop be provided?",
    text: "The claim and bonus airdrop for YourTrump will be processed sequentially after the presale ends. Once the presale concludes, investors will receive their tokens along with the bonus when they complete the claim process through their wallet. The project team will provide technical support and regular updates to ensure the smooth execution of the airdrop. While the distribution may take some time to ensure accuracy and stability, it will be completed as quickly as possible.",
    reply: "20K",
    reTweet: "58K",
    like: "452K",
    view: "44M",
  },
];

const Faq = () => {
  const [expandedIndices, setExpandedIndices] = useState([]); // Use an array to track expanded indices

  const toggleText = (index) => {
    if (expandedIndices.includes(index)) {
      setExpandedIndices(expandedIndices.filter((i) => i !== index)); // Remove index if already expanded
    } else {
      setExpandedIndices([...expandedIndices, index]); // Add index if not already expanded
    }
  };

  return (
    <Box sx={{ mt: -4.9, mb: { xs: -22, md: -14 } }} id="faqs">
      <Box>
        <Box
          sx={{ display: "flex", gap: "0 10px", mx: { xs: 1, md: 2 }, mt: 2 }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: { xs: "25px", md: "40px" } }}>
              <img
                src={logo}
                alt=""
                srcSet=""
                style={{
                  width: "100%",
                  objectFit: "contain",
                  borderRadius: "49%",
                }}
              />
            </Box>
            <Box sx={{ width: "2px", height: "100%", background: "#393D40" }} />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", gap: "0 10px" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "0 10px" }}
              >
                <Box>
                  <Box sx={{}}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "0 10px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "17px",
                            display: "flex",
                            alignItems: "center",
                            gap: "0 7px",
                            fontFamily: "HelveticaNeueBlackBold",
                          }}
                        >
                          YourTrump{" "}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#4B4D51",
                            fontFamily: "HelveticaNeueBlack",
                          }}
                        >
                          $YTP
                        </Typography>
                      </Box>
                      <Box>
                        <MoreHorizSharpIcon
                          sx={{ fontSize: "24px", color: "#757575" }}
                        />
                      </Box>
                    </Box>
                    <Box sx={{}}>
                      <Typography
                        sx={{ color: "#fff", fontFamily: "HelveticaNeueBlack" }}
                      >
                        FAQs
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        pt: 3,
                        width: {
                          xs: "calc(100% - 35px)",
                          md: "calc(100% - 35px)",
                        },
                        mx: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          mx: "auto",
                          pl: { xs: "0px", md: "0px" },
                        }}
                      >
                        <img
                          src={faq}
                          alt=""
                          srcSet=""
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            height: "100%",
                            borderRadius: "15px",
                          }}
                        />
                      </Box>
                      <Box sx={{ mt: -1 }}>
                        <Icons
                          reply="11K"
                          reTweet="41K"
                          like="355K"
                          view="25M"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            color: "#fff",
            px: { xs: 1, md: 2 },
            pt: 2,
            display: "flex",
            flexDirection: "column",
            gap: "7px 0px",
          }}
        >
          {faqArray.map((item, index) => {
            const isExpanded = expandedIndices.includes(index);
            const displayText = isExpanded
              ? item.text
              : `${item.text.slice(0, 290)}...`;
            const showMoreEnabled = index > 1; // Enable "Show More" for items after the first two

            return (
              <Box key={index} sx={{ display: "flex", gap: "0 10px" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ width: { xs: "25px", md: "40px" } }}>
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        borderRadius: "49%",
                      }}
                    />
                  </Box>

                  {item.borderB ? (
                    <>
                      <Box
                        sx={{
                          width: "2px",
                          background: "#393D40",
                          color: "#393D40",
                          height: "100%",
                          mx: "auto",
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </Box>

                <Box
                  sx={{
                    p: { xs: 0, sm: 1 },
                    py: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px 0",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "0 14px", width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "0px 10px",
                          width: "100%",
                        }}
                      >
                        <Box>
                          <Box sx={{ display: "flex", gap: "0 14px" }}>
                            <Typography
                              sx={{
                                color: "#fff",
                                fontSize: "17px",
                                display: "flex",
                                alignItems: "center",
                                gap: "0 7px",
                                fontFamily: "HelveticaNeueBlackBold",
                              }}
                            >
                              YourTrump
                            </Typography>
                            <Typography
                              sx={{
                                color: "#4B4D51",
                                fontFamily: "HelveticaNeueBlack",
                              }}
                            >
                              $YTP
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontFamily: "HelveticaNeueBlack",
                              }}
                            >
                              {item.heading}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box>
                        <MoreHorizSharpIcon
                          sx={{ fontSize: "24px", color: "#757575" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid grey",
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px 0",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: "0 14px" }}>
                        <Box sx={{ width: { xs: "20px", md: "33px" } }}>
                          <img
                            src={logo}
                            alt="logo"
                            style={{
                              width: "100%",
                              objectFit: "contain",
                              borderRadius: "49%",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0 14px",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: "17px",
                              display: "flex",
                              alignItems: "center",
                              gap: "0 7px",
                              fontFamily: "HelveticaNeueBlackBold",
                            }}
                          >
                            YourTrump
                          </Typography>
                          <Typography
                            sx={{
                              color: "#4B4D51",
                              fontFamily: "HelveticaNeueBlack",
                            }}
                          >
                            $YTP
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            // fontSize: { xs: "15px", md: "15px" },
                            fontFamily: "HelveticaNeueBlack",
                            color: "#fff",
                          }}
                        >
                          {showMoreEnabled ? displayText : item.text}
                        </Typography>
                        {showMoreEnabled && (
                          <Typography
                            onClick={() => toggleText(index)}
                            sx={{
                              color: "#1DA1F2",
                              cursor: "pointer",
                              fontSize: "14px",
                              mt: 1,
                              fontWeight: 500,
                            }}
                          >
                            {isExpanded ? "Show Less" : "Show More"}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Icons
                      reply={item.reply}
                      reTweet={item.reTweet}
                      like={item.like}
                      view={item.view}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
        {/* <Box
          sx={{
            py: 3,
            width: { xs: "calc(100% - 35px)" },
            mx: "auto",
          }}
        >
          <Box sx={{ width: "100%", height: "100%", mx: "auto" }}>
            <img
              src={faq}
              alt="faq"
              style={{
                width: "100%",
                objectFit: "cover",
                height: "100%",
                borderRadius: "15px",
              }}
            />
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Faq;
