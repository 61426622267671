import { Box, Typography } from "@mui/material";
import React from "react";

const Disclaimer = () => {
  return (
    <Box sx={{ color: "#fff", p: 2 }}>
      <Typography
        sx={{
          color: "#808080",
          textAlign: "center",
          fontFamily: "HelveticaNeueBlack",
        }}
      >
        © 2024 YTP. All rights reserved.
      </Typography>
      <Typography
        sx={{
          color: "#808080",
          textAlign: "center",
          fontSize: "14px",
          fontFamily: "HelveticaNeueBlack",
        }}
      >
        The cryptocurrency market can be volatile. Profits may be subject to
        capital gains or other taxes depending on your location, so be mindful
        of your tax obligations. Regulations vary by region, so it's important
        to understand the rules that apply to you. Conduct thorough research and
        only invest what you can afford to lose.
      </Typography>
    </Box>
  );
};

export default Disclaimer;
