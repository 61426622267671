import { readContract, writeContract } from "wagmi/actions";
import { waitForTransaction } from "@wagmi/core";

// Read Write hooks

export const preSaleReadFunction = async (
  networkContract,
  functionName,
  args
) => {
  console.log("networkContract", {
    networkContract,
  });
  const data = await readContract({
    address: networkContract?.presaleAddress,
    abi: networkContract?.presaleAbi,
    functionName,
    args,
    chainId: networkContract?.chainId,
  });

  return data;
};

export const usdtReadFunction = async (networkContract, functionName, args) => {
  const data = await readContract({
    address: networkContract.usdtAddress,
    abi: networkContract.usdtAbi,
    functionName,
    args,
  });
  return data;
};

/// write functions

export const preSaleWriteFunction = async (
  networkContract,
  functionName,
  args,
  value
) => {
  const { hash } = await writeContract({
    address: networkContract.presaleAddress,
    abi: networkContract.presaleAbi,
    functionName,
    args,
    value,
  });
  console.log("BlockHash..", hash);
  const { transactionHash } = await waitForTransaction({
    hash,
  });
  return transactionHash;
};

export const usdtWriteFunction = async (
  networkContract,
  functionName,
  args
) => {
  const { hash } = await writeContract({
    address: networkContract.usdtAddress,
    abi: networkContract.usdtAbi,
    functionName,
    args,
  });
  const { transactionHash } = await waitForTransaction({
    hash,
  });
  return transactionHash;
};
