import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";

import { WagmiConfig } from "wagmi";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { sepolia, mainnet, bsc } from "wagmi/chains";

const projectId = "cba73ada547c01c1a64d7725fb732495";
const chains = [sepolia, mainnet, bsc];
export const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  enableWalletConnect: true,
  autoConnect: true,
  defaultChain: mainnet,
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: "dark",
  defaultChain: mainnet,
  enableAccountView: true,
  autoConnect: true,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WagmiConfig config={wagmiConfig}>
    <BrowserRouter>
      <CssBaseline />
      <App />
    </BrowserRouter>
  </WagmiConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
