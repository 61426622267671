/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
import axios from "axios";

const TwitterUser = ({ fontSize, setTotalFollowers }) => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const options = {
        method: "GET",
        url: "https://twitter241.p.rapidapi.com/user",
        params: { username: "realdonaldtrump" },
        headers: {
          "x-rapidapi-key":
            // "5be5cb0b12msh8522bc605f38b02p15f982jsn569435335804", // Test net
            "04a776181fmsha2d082d2beee58bp1993f1jsn393dbeebd111", // Main net buyed plan
          "x-rapidapi-host": "twitter241.p.rapidapi.com",
        },
      };

      const maxRetries = 3;
      let retries = 0;
      let success = false;
      let delay = 1000; // Initial delay of 1 second

      while (retries < maxRetries && !success) {
        try {
          const response = await axios.request(options);
          const data = response?.data;
          setUserData(data);

          // Extract followers count
          const followers =
            data?.result?.data?.user?.result?.legacy?.followers_count;
          console.log("followers", followers);
          setTotalFollowers(followers);

          success = true; // Set success to true if request succeeds
        } catch (err) {
          if (err.response && err.response.status === 429) {
            // Rate limit error
            console.warn(`Retry attempt ${retries + 1}: waiting ${delay}ms`);
            await new Promise((resolve) => setTimeout(resolve, delay));
            delay *= 2; // Exponential backoff
            retries += 1;
          } else {
            // Handle other errors
            setError(err);
            console.error(err);
            break;
          }
        }
      }

      if (!success) {
        console.error("Failed to fetch data after multiple attempts.");
      }
    };

    fetchUserData();
  }, [setTotalFollowers]);

  return <div></div>;
};

export default TwitterUser;
