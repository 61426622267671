import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as ScrollLink } from "react-scroll";
import {
  Button,
  Container,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Home from "./Home/Home";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterUser from "../Pages/TwitterUser";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";

import {
  audit,
  logo,
  navAbout,
  navContactUs,
  navFaqs,
  navHowToBuy,
  navRoadmap,
  navTokenomics,
  telegram,
  WP,
} from "../Images";
import { Link } from "react-router-dom";

const array = [
  {
    name: "",
    link: "https://tr.ee/YTP_x",
    icons: <XIcon sx={{ color: "#FEFEFF", fontSize: "34px" }} />,
  },
];

export default function TemporaryDrawer() {
  const [totalFollowers, setTotalFollowers] = React.useState(0);
  const [estimatedPrice, setEstimatedPrice] = React.useState(0);
  React.useEffect(() => {
    const dotFunction = () => {
      const lastSlice = totalFollowers.toString().slice(-5);
      const withDecimal = `${lastSlice[0]}.${lastSlice.slice(1)}`; // Insert decimal after the first digit
      setEstimatedPrice(withDecimal);
    };

    dotFunction(); // Call the function inside useEffect
  }, [totalFollowers]);

  const [open, setOpen] = React.useState(false);

  const downloadPDF = () => {
    const pdfUrl = "/whitepaper.pdf"; // Adjust the path to match the exact file name
    window.open(pdfUrl, "_blank");
  };
  const downloadAudit = () => {
    const pdfUrl = "/Audit.pdf"; // Adjust the path to match the exact file name
    window.open(pdfUrl, "_blank");
  };

  const array2 = [
    // {
    //   name: "Home",
    //   link1: "home",
    // },
    {
      name: "About YourTrump",
      link1: "presale",
      offset: -130,
      img: navAbout,
    },
    {
      name: "Tokenomics",
      link1: "Tokenomics",
      offset: -137,
      img: navTokenomics,
    },
    {
      name: "Roadmap",
      link1: "Roademap",
      offset: -130,
      img: navRoadmap,
    },
    {
      name: "How To Buy",
      link1: "howtobuy",
      offset: -130,
      img: navHowToBuy,
    },
    {
      name: "Faqs",
      link1: "faqs",
      offset: -144,
      img: navFaqs,
    },
    {
      name: "Audit",
      img: audit,
      click: downloadAudit,
    },
    {
      name: "WhitePaper",
      img: WP,
      click: downloadPDF,
    },
  ];

  // ---------------------------  Font Size --------------------------------
  const isSmallScreen = useMediaQuery("(max-width:600px)"); // xs
  const isMediumScreen = useMediaQuery(
    "(min-width:600px) and (max-width:900px)"
  ); // md
  const isLargeScreen = useMediaQuery("(min-width:900px)"); // lg

  const fontSize = isSmallScreen
    ? "12px"
    : isMediumScreen
    ? "13px"
    : isLargeScreen
    ? "16px"
    : "12px"; // Default fallback

  return (
    <Container sx={{}}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          position: "relative",
          justifyContent: "space-between",
          // border: "2px solid red",
        }}
      >
        <Hidden lgDown>
          <Box
            sx={{
              width: { xs: open ? "250px" : "35px", lg: "250px" },
              background: "#000",
              height: "100%",
              position: "fixed",
              // zIndex: "10000",
            }}
          >
            {" "}
            <List
              sx={{
                background: "#000",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                {array.map((item) => (
                  <Box
                    key={item.name}
                    disablePadding
                    sx={{}}
                    component={Link}
                    to={item.link}
                    target="_blank"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "30px 10px",
                        alignItems: "center",
                        justifyContent: "start",
                        mx: 2,
                        mt: 1,
                      }}
                    >
                      {item.img ? (
                        <>
                          <Box sx={{ width: "34px" }}>
                            <img
                              src={item.img}
                              alt=""
                              srcSet=""
                              style={{ width: "100%", objectFit: "contain" }}
                            />
                          </Box>
                        </>
                      ) : (
                        <Box>{item.icons}</Box>
                      )}

                      <Box>
                        <Typography
                          sx={{
                            fontSize: "17px",
                            textTransform: "uppercase",
                            color: "#fff",
                            display: "inline-block",
                            flexWrap: "nowrap",
                            // fontWeight: "bold",
                            whiteSpace: "nowrap",
                            fontFamily: "HelveticaNeueBlack",
                            textAlign: "left",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
                <Box
                  sx={{
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "5px",
                      alignItems: "start",
                      gap: "20px",
                      px: 2,
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "column",
                      mt: 5,
                      // border: "2px solid red",
                    }}
                  >
                    <>
                      <Box
                        disablePadding
                        sx={{}}
                        component={Link}
                        to="https://tr.ee/YTP_telegram"
                        target="_blank"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0px 10px",
                            alignItems: "center",
                            justifyContent: "start",
                            // border: "2px solid red",
                            mt: 1,
                          }}
                        >
                          <Box sx={{ width: "34px" }}>
                            <img
                              src={telegram}
                              alt=""
                              srcSet=""
                              style={{ width: "100%", objectFit: "contain" }}
                            />
                          </Box>

                          <Box>
                            <Typography
                              sx={{
                                fontSize: "17px",
                                textTransform: "uppercase",
                                color: "#fff",
                                display: "inline-block",
                                flexWrap: "nowrap",
                                whiteSpace: "nowrap",
                                fontFamily: "HelveticaNeueBlack",
                                textAlign: "left",
                              }}
                            >
                              telegram
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      {array2.map((item, index) => (
                        <ScrollLink
                          onClick={item.click ? item.click : ""}
                          key={index}
                          to={item.link1}
                          spy={true}
                          smooth={true}
                          offset={item.offset}
                          duration={1000}
                          activeClass="active-link"
                          style={{
                            cursor: "pointer",
                            textDecoration: "none",
                            padding: { xs: "4px", sm: "10px" },
                            // borderRadius: "5px",
                            fontSize: "17px",
                            textTransform: "uppercase",
                            color: "#fff",
                            display: "inline-block",
                            flexWrap: "nowrap",
                            // fontWeight: "bold",
                            whiteSpace: "nowrap",
                            fontFamily: "HelveticaNeueBlack",
                            textAlign: "left",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "0 10px",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                width: "34px",
                                background: "#000",

                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={item.img}
                                alt=""
                                srcSet=""
                                style={{ width: "100%", objectFit: "contain" }}
                              />
                            </Box>
                            <span>{item.name}</span>
                          </Box>
                        </ScrollLink>
                      ))}

                      <style>
                        {`
                   .active-link {
                      color: #fff !important; /* Force color override */
                      border-bottom: 4px solid #1D9BF0 !important; /* Border under the active link */
                      // padding-bottom:4px;
                     }
                  `}
                      </style>
                    </>
                  </Box>
                </Box>
              </Box>
              <Box sx={{}}>
                <Box sx={{ my: 4, px: 2, width: "100%", mt: 4 }}>
                  <Button
                    LinkComponent={Link}
                    target="_blank"
                    to="https://linktr.ee/realYourTrump"
                    sx={{
                      background: "#1D9BF0",
                      color: "#fff",
                      borderRadius: "25px",
                      py: 1.3,
                      px: 3,
                      width: "100%",
                    }}
                  >
                    Contact Us
                  </Button>
                </Box>
                <Box
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      px: 2,
                      width: "100%",
                      display: "flex",
                      gap: "0 10px",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "25px", md: "49px" },
                      }}
                    >
                      <img
                        src={logo}
                        alt=""
                        srcSet=""
                        style={{
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: "49%",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "0px 0px",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "18px",
                          fontFamily: "HelveticaNeueBlackBold",
                        }}
                      >
                        YourTrump
                      </Typography>
                      <Typography
                        sx={{
                          color: "#4B4D51",
                          fontFamily: "HelveticaNeueBlack",
                        }}
                      >
                        @YTP
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <IconButton sx={{}}>
                      <MoreHorizSharpIcon
                        sx={{
                          fontSize: "24px",
                          color: "#757575",
                          fontFamily: "HelveticaNeueBlack",
                        }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </List>
          </Box>
        </Hidden>

        <Box
          sx={{
            // px: { xs: 0, sm: 2, md: 2 },
            width: { xs: "calc(100% - 0px)", lg: "calc(100% - 250px)" },
            ml: { xs: "0px", lg: "250px" },
            background: "#000",
            mx: "auto",
            boxSizing: "border-box",
            // border: "2px solid red",
          }}
        >
          <Home
            totalFollowers={totalFollowers}
            setTotalFollowers={setTotalFollowers}
            estimatedPrice={estimatedPrice}
            setEstimatedPrice={setEstimatedPrice}
          />
          <TwitterUser fontSize="30px" setTotalFollowers={setTotalFollowers} />
        </Box>
      </Box>
    </Container>
  );
}
