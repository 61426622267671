import {
  mainnet,
  sepolia,
  bsc,
  bscTestnet,
  // polygonAmoy,
} from "wagmi/chains";
// import tokenAbi from "./tokenAbi.json";
import preSaleETHAbi from "./preSaleETHAbi.json";
import preSaleBSCAbi from "./preSaleBSCAbi.json";
import EthUSDTAbi from "./EthUSDTAbi.json";
import BscUsdtAbi from "./BscUsdtAbi.json";

import { erc20ABI } from "@wagmi/core";

// import { erc20Abi } from "viem";

const ChainId = {
  ETHEREUM: mainnet.id,
  SEPOLIA: sepolia.id,
  BSC: bsc.id,
  BSC_TESTNET: bscTestnet.id,
  // POLYGON_AMOY: polygonAmoy.id,
};

// export const tokenAddress = "0xE29f83F87a1818d9Bd3e8EBbB0cb3d5556B6eA1e"; //testnet
// export const tokenAddress = "0x110429D46Bb2e1E731F390e74E045f86E524ff24"; // mainnet

// export const usdtAddress = "0xb04678C0cac78245ddC7E728cB5120924dd18aB6"; // testnet
export const usdtAddress = "0xb04678C0cac78245ddC7E728cB5120924dd18aB6"; // mainnet

// export const preSaleAddress = "0xD59d18F893331454C3a913326101fdED820D65d1"; // testnet
export const preSaleAddress = "0x6a315C44Ec9B2867882E4B4A1Cf185Ca8d8784D5"; //mainnet

export const PRESALE_ADDRESS = {
  [ChainId.ETHEREUM]: "0xb38eF8634E7263c408382024897629938aCf5CC0",
  // [ChainId.SEPOLIA]: "0x6a315C44Ec9B2867882E4B4A1Cf185Ca8d8784D5",
  [ChainId.BSC]: "0xD172F6c4B96D3367052f839F68aa95B6B8428335",
  // [ChainId.BSC_TESTNET]: "0xbe411959754c7573341052a2f438d10b6ddd72f6",
};

// export const TOKEN_ADDRESS = {
//   [ChainId.ETHEREUM]: "0x110429D46Bb2e1E731F390e74E045f86E524ff24",
//   [ChainId.SEPOLIA]: "0xE29f83F87a1818d9Bd3e8EBbB0cb3d5556B6eA1e",
//   [ChainId.BSC]: "0xE29f83F87a1818d9Bd3e8EBbB0cb3d5556B6eA1e",
//   [ChainId.BSC_TESTNET]: "0xE29f83F87a1818d9Bd3e8EBbB0cb3d5556B6eA1e",
// };

export const USDT_ADDRESS = {
  [ChainId.ETHEREUM]: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  // [ChainId.SEPOLIA]: "0xb04678C0cac78245ddC7E728cB5120924dd18aB6",
  [ChainId.BSC]: "0x55d398326f99059fF775485246999027B3197955",
  // [ChainId.BSC_TESTNET]: "0x90c38b4504795d0DDb9fF166706F54bcD064cca4",
};

export const getNetworkContract = (networkID) => {
  const presaleAddress = PRESALE_ADDRESS[networkID];
  const usdtAddress = USDT_ADDRESS[networkID];
  switch (networkID) {
    case 1:
      return {
        presaleAddress: presaleAddress,
        // tokenAddress: TOKEN_ADDRESS[networkID],
        presaleAbi: preSaleETHAbi,
        // tokenAbi: tokenAbi,
        usdtAddress: usdtAddress,
        usdtAbi: EthUSDTAbi,
        chainId: 1,
      };
    case 11155111:
      return {
        presaleAddress: presaleAddress,
        // tokenAddress: TOKEN_ADDRESS[networkID],
        presaleAbi: preSaleETHAbi,
        // tokenAbi: tokenAbi,
        usdtAddress: usdtAddress,
        usdtAbi: EthUSDTAbi,
        chainId: 11155111,
      };

    case 56:
      return {
        presaleAddress: presaleAddress,
        presaleAbi: preSaleBSCAbi,
        usdtAddress: usdtAddress,
        usdtAbi: BscUsdtAbi,
        chainId: 56,
      };
    case 97:
      return {
        presaleAddress: presaleAddress,
        presaleAbi: preSaleBSCAbi,
        usdtAddress: usdtAddress,
        usdtAbi: BscUsdtAbi,
        chainId: 97,
      };

    default:
      return {
        presaleAddress: presaleAddress,
        presaleAbi: preSaleETHAbi,
        usdtAddress: usdtAddress,
        usdtAbi: EthUSDTAbi,
        chainId: 11155111,
      };
  }
};
