import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Tabss() {
  return (
    <Box
      sx={{
        width: "100%",
        background: "#000",
        display: "flex",
        justifyContent: "space-between",
        // mt: 2,
        px: { xs: 0, sm: 2, md: 1 },
        mb: -7,
        flexWrap: "wrap",
        pt: 1,
        // border: "2px solid red",
      }}
    >
      <Box
        // component={Link}
        // to={"https://tr.ee/YTP_highlights"}
        // sx={{ textDecoration: "none" }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "bold",
            pb: 1,
            borderBottom: "4px solid #619DE9",
            fontSize: { xs: "15px", sm: "18px" },
          }}
        >
          Posts
        </Typography>
      </Box>
      <Box
        component={Link}
        to={"https://tr.ee/YTP_highlights"}
        target="_blank"
        sx={{ textDecoration: "none" }}
      >
        <Typography
          sx={{
            color: "#777777",
            fontWeight: "bold",
            fontSize: { xs: "15px", sm: "18px" },
          }}
        >
          Replies
        </Typography>
      </Box>
      <Box
        component={Link}
        to={"https://tr.ee/YTP_media"}
        target="_blank"
        sx={{ textDecoration: "none" }}
      >
        <Typography
          sx={{
            color: "#777777",
            fontWeight: "bold",
            fontSize: { xs: "15px", sm: "18px" },
          }}
        >
          Media
        </Typography>
      </Box>
    </Box>
  );
}
