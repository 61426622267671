import { Box, Typography } from "@mui/material";
import moment from "moment-timezone";
import { useState, useEffect } from "react";
import { StyledBox } from "./StyleBox";

const CountDown = ({ time }) => {
  const targetDate = moment.tz("2024-12-20T09:00:00", "America/New_York");
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());
  function getTimeRemaining() {
    const now = moment();
    const duration = moment.duration(targetDate.diff(now));
    return {
      days: Math.floor(duration.asDays()),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(getTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: { xs: "0px 20px", md: "0px 2px", lg: "0px 20px" },
          textAlign: "center",
          background: "rgba(113, 118, 122, 0.3)",
          borderRadius: "12px",
          py: 1,
        }}
      >
        <Box sx={{}}>
          <StyledBox>
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "14px", sm: "18px" },
              }}
            >
              Days
            </Typography>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "20px", sm: "27px" },
                fontWeight: "bold",
                lineHeight: "100%",
                color: "#fff",
              }}
            >
              {timeRemaining?.days}
            </Typography>
          </StyledBox>
        </Box>
        <Box sx={{}}>
          <StyledBox>
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "14px", sm: "18px" },
              }}
            >
              Hours
            </Typography>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "20px", sm: "27px" },
                fontWeight: "bold",
                lineHeight: "100%",
                color: "#fff",
              }}
            >
              {timeRemaining?.hours < 10
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                  "0" + timeRemaining?.hours
                : timeRemaining?.hours}
            </Typography>
          </StyledBox>
        </Box>
        <Box sx={{}}>
          <StyledBox>
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "14px", sm: "18px" },
              }}
            >
              Min
            </Typography>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "20px", sm: "27px" },
                fontWeight: "bold",
                lineHeight: "100%",
                color: "#fff",
              }}
            >
              {timeRemaining?.minutes < 10
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                  "0" + timeRemaining?.minutes
                : timeRemaining?.minutes}
            </Typography>
          </StyledBox>
        </Box>
        <Box sx={{}}>
          <StyledBox>
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: "14px", sm: "18px" },
              }}
            >
              Sec
            </Typography>
            <Typography
              variant="subtitle"
              sx={{
                fontSize: { xs: "20px", sm: "27px" },
                fontWeight: "bold",
                lineHeight: "100%",
                color: "#fff",
              }}
            >
              {timeRemaining?.seconds < 10
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                  "0" + timeRemaining?.seconds
                : timeRemaining?.seconds}
            </Typography>
          </StyledBox>
        </Box>
      </Box>
    </>
  );
};

export default CountDown;
