import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { presale, logo } from "../Images";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import Icons from "../SmallComponent/Icons";



const PresaleInfo = () => {
  const [showMore, setShowMore] = useState(false);

  const text = `The cryptocurrency market is constantly evolving, with new projects aiming to break traditional molds. YourTrump is an innovative ICO project that leverages Donald Trump’s Twitter follower count to determine token pricing. This groundbreaking approach is designed to capture market attention and spark curiosity, offering investors a unique opportunity. By combining Trump’s influence with strategic investment value, YourTrump enhances its market competitiveness and provides investors with a differentiated experience.`;

  const truncatedText = `The cryptocurrency market is constantly evolving, with new projects aiming to break traditional molds. YourTrump is an innovative ICO project that leverages Donald Trump’s Twitter follower count to determine token pricing. This groundbreaking approach is designed to ...`;

  return (
    <Box
      sx={{
        pt: 2,
        borderTop: "1px solid #2F3336",
        borderBottom: "1px solid #2F3336",
      }}
      id="presale"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: { xs: 1, md: 2 },
        }}
      >
        <Box sx={{ display: "flex", gap: "0 10px" }}>
          <Box
            sx={{
              width: { xs: "25px", md: "40px" },
            }}
          >
            <img
              src={logo}
              alt=""
              style={{
                width: "100%",
                objectFit: "contain",
                borderRadius: "49%",
              }}
            />
          </Box>
          <Box sx={{ width: { xs: "calc(100% - 35px)" } }}>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between",alignItems:"flex-start", }}>
                <Box sx={{ display: "flex", gap: "0px 10px" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "18px",
                      fontFamily: "HelveticaNeueBlackBold",
                    }}
                  >
                    YourTrump
                  </Typography>
                  <Typography
                    sx={{ color: "#4B4D51", fontFamily: "HelveticaNeueBlack" }}
                  >
                    $YTP
                  </Typography>
                </Box>
                <Box>
                  
                    <MoreHorizSharpIcon
                      sx={{ fontSize: "24px", color: "#757575" }}
                    />
                 
                </Box>
              </Box>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "15px",
                  fontFamily: "HelveticaNeueBlack",
                }}
              >
                {showMore ? text : truncatedText}
                <br/>
                <Typography
                  component="span"
                  onClick={() => setShowMore(!showMore)}
                  sx={{
                    color: "#1DA1F2",
                    cursor: "pointer",
                    fontSize: "15px",
                    fontFamily: "HelveticaNeueBlack",
                  
                  }}
                >
                  {showMore ? "Show Less" : "Show More"}
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ pt: 2 }}>
              <Box sx={{ width: "100%", height: "100%", mx: "auto" }}>
                <img
                  src={presale}
                  alt=""
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    height: "100%",
                    borderRadius: "15px",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: 2 }}>
        <Box
          sx={{
            px:2,
            pl: { xs: 4, sm: 4, md:8 },
          }}
        >
         <Icons reply="39K" reTweet="78K" like="397K" view="78M"  />
        </Box>
      </Box>
    </Box>
  );
};

export default PresaleInfo;
